import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

const NotFoundPage = ({ data }) => {
    React.useEffect(() => {
        document.body.classList.add('colored-nav');

        return () => document.body.classList.remove('colored-nav');
    }, []);
    return (
        <Layout dataMenu={data.allMenuLinkContentMenuLinkContent} dataFooterSettings={data.nodeSiteSettings}>
            <SEO title="404: Not found" />
            <section className="section">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn't exist... the sadness.</p>
            </section>
        </Layout>
    );
};

export const pageQuery = graphql`
    query($langcode: String) {
        allMenuLinkContentMenuLinkContent(
            filter: {
                relationships: { field_language_code: { field_language_code: { eq: $langcode } } }
                enabled: { eq: true }
            }
            sort: { fields: weight, order: ASC }
        ) {
            edges {
                node {
                    weight
                    name: title
                    drupal_id
                    link {
                        uri
                    }
                    drupal_parent_menu_item
                    langcode
                    relationships {
                        field_content {
                            ...LegalMenuItems
                            ...ParagraphMenuItems
                            ...ContactMenuItems
                            ...NutritionMenuItems
                            ...ProductsMenuItems
                        }
                        field_collection_related {
                            ...CollectionMenuItems
                        }
                    }
                    menu_name
                    enabled
                    bundle
                }
            }
        }
        nodeSiteSettings(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...SiteSettingsSomeItems
        }
    }
`;

export default NotFoundPage;
